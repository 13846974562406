<template>
  <div>
    <v-card>
      <!-- table -->
      <v-data-table
        :headers="columns"
        :items="items"
        :options.sync="options"
        :server-items-length="itemsTotal"
        :loading="loading"
        class="text-no-wrap"
      >
        <!-- main -->
        <template #[`item.main`]="{ item }">
          <span class="text-no-wrap">{{ item.main ? 'Yes' : 'No' }}</span>
        </template>

        <!-- register date -->
        <template #[`item.registered_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.registered_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- activate date -->
        <template #[`item.activated_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.activated_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- expiry date -->
        <template #[`item.expired_at`]="{ item }">
          <span class="text-no-wrap">{{ dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiEdit }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        paginate();
        aside = false
      "
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiEdit, mdiDotsVertical } from '@mdi/js'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ref } from '@vue/composition-api'
import FormAside from './FormAside'

dayjs.extend(utc)

export default {
  components: {
    FormAside,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Card Number', value: 'card_number' },
      { text: 'Main User', value: 'main' },
      { text: 'First Name', value: 'first_name' },
      { text: 'Last Name', value: 'last_name' },
      { text: 'Gender', value: 'gender' },
      { text: 'Birth Date', value: 'birth_date' },
      { text: 'Phone', value: 'phone' },
      { text: 'Relationship', value: 'relationship' },
      { text: 'Register Date', value: 'registered_at' },
      { text: 'Activate Date', value: 'activated_at' },
      { text: 'Expiry Date', value: 'expired_at' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const aside = ref(false)

    const setAside = item => {
      aside.value = true
      resource.value = item
    }

    return {
      t,
      dayjs,
      formatDate,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      setAside,
      aside,
      resource,

      icons: {
        mdiDotsVertical,
        mdiEdit,
      },
    }
  },
}
</script>
