var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.main",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.main ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.registered_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.dayjs(item.registered_at).format('YYYY-MM-DD HH:mm:ss')))])];
      }
    }, {
      key: "item.activated_at",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.dayjs(item.activated_at).format('YYYY-MM-DD HH:mm:ss')))])];
      }
    }, {
      key: "item.expired_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss')))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiEdit) + " ")]), _c('span', [_vm._v("Edit")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1), _vm.aside ? _c('form-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.paginate();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }